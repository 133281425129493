import React from "react"
import { Script } from 'gatsby';
import { Helmet } from "react-helmet"
import { MicroCmsImage, CPostCard02, CJumbotron, CBreadCrumb, LWrap, CHeroImg, CustomLink, CSectTitle, CDefinition, CCard, CBtnList, LPlan, CContactBox, CReservationBox } from "../_index"
import formatDateJa from "../../utils/format-date-ja"
import periodText from "../../utils/period-text"
import MicroCmsContents from "./microcms_contents"
import planChoice from "../../utils/plan-choice"
import planGet from "../../utils/plan-get"

const AestheticLayout = ({ data }: { data: any }) => {

  return (
    <>
      <Script dangerouslySetInnerHTML={{
        __html: `
(function(){
    const sentenceAtag = document.querySelectorAll('.js_articleSentence a');
    for (let i = 0; i < sentenceAtag.length; i++) {
      const target = sentenceAtag[i].getAttribute('target')
      const icon_blank = document.createElement("i")
      icon_blank.className = 'icon-blank';
      const pdf_check = /\.pdf$/.test(sentenceAtag[i].getAttribute('href'))
      const icon_pdf = document.createElement("i")
      icon_pdf.className = 'icon-pdf';
      if(pdf_check){
        sentenceAtag[i].prepend(icon_pdf);
      }else if(target == "_blank"){
        sentenceAtag[i].appendChild(icon_blank);
      }
    }
})(document)
        `}} />
      <CJumbotron
        data={{
          title: {
            en: data.head_title,
            ja: data.title.split('\n').map((t: string) => (<>{t}<br /></>))
          },
          imgList: [
            {
              img: {
                src: data.eyecatch.url
              }
            }
          ]
        }}
      />
      <CBreadCrumb data={{
        parent: [
          {
            label: 'エステ',
            path: '/aesthetic/'
          },
        ],
        current: {
          label: data?.title.includes('-') ? data?.title.split('-')[0] : data?.title
        }
      }} />
      <div className="l_sect">
        <section className="u_mbExLarge">
          <LWrap>
            <h2 className="c_headingLv2">{data.contents_title && data.contents_title.split('\n').map((t: string) => (<>{t}<br /></>))}</h2>
            <p className="c_sectLead u_mb30">{data.description && data.description.split('\n').map((t: string) => (<>{t}<br /></>))}</p>
          </LWrap>
        </section>
        <section className="u_mbExLarge">
          <LWrap>
            <MicroCmsContents data={data.contents} />
            {data.contact && <CReservationBox plan_name={data.title} />}
            <CSectTitle
              title={{
                ja: 'プラン',
                en: <>PLAN</>,
              }}
            />
            <p className="c_sectLead u_mbMedium">
              リゾートホテルを満喫できる
              <br />
              女性にうれしいエステプラン
            </p>
            <CPostCard02 data={planChoice(planGet(), ['エステ'], 0)} />
          </LWrap>
        </section>
      </div>
      <LPlan />
    </>
  )
}
export default AestheticLayout;
